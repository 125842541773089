import ng from 'angular';

import { PanelHeaderData } from '@/atomic-components/molecules';
import { UiRights } from '@/configuration';
import { AuthContextService, NavigationService, WebhostingRobotService } from '@/services';
import * as Types from '@/types';

export class TemplateWebspaceAccessAddUserOverviewController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'authContext',
        'navigation',
        'webhostingRobot'
    ];

    public pageHeaderData: PanelHeaderData;
    public webspace: Types.WebhostingApi.Webspace;
    public userPanelRight: Record<string, boolean>;
    public startWithEditStatus: boolean;

    public name: string;
    public password: string;
    public homeDir = '';
    public comments: string;
    public sshKey: string;
    public accessLevels: Record<
    'ftpAccess' |
    'sshAccess' |
    'statsAccess' |
    'ftpLimited', { value: boolean }>;

    public faqArticleIdList: string[] = [
        'webspace-ftp-access',
        'webspace-ftp-folders',
        'webspace-file-directory'
    ];
    public backLink: string;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private navigation: NavigationService,
        private webhostingRobot: WebhostingRobotService
    ) {}

    public $onInit(): void {
        this.backLink = this.$state.current.name
            .split('.')
            .filter((path) => !path.includes('new'))
            .join('.');
        this.startWithEditStatus = true;
        const panelHeaderBackwardLink = 'webhosting.webspaces.id.edit';
        const panelHeaderBackwardText = this.$translate.instant('TR_140119-e915b4_TR');
        const panelHeaderRouteParams = {webspaceId: this.webspace.id};

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'folder',
            panelTitle: this.$translate.instant('TR_080119-3313e9_TR')
        };

        this.userPanelRight = {
            createUser:  this.authContext.isGranted(UiRights.WEB_USER_CREATE),
            deleteUser:  this.authContext.isGranted(UiRights.WEB_USER_DELETE),
            editPanelButton: ['active', 'restricted'].indexOf(this.webspace.status) >= 0
                && this.authContext.isGrantedAny(
                    [UiRights.WEB_USER_EDIT, UiRights.WEB_USER_DELETE, UiRights.WEB_USER_CREATE]
                ),
            editUser:  this.authContext.isGranted(UiRights.WEB_USER_EDIT)
        };
    }

    public save = (): void => {
        void this.webhostingRobot.createUser(
                {
                    name: this.name,
                    accountId: AuthContextService.account.id,
                    comments: this.comments,
                    sshKey: this.sshKey
                },
                this.password
            )
            .then((response) => response.response)
            .then(({ id }: { id: string }) => {
                if (this.webspace.accesses?.length < 0) {
                    this.webspace.accesses = [];
                }
                const access = {
                    userId: id,
                    ftpAccess: this.accessLevels.ftpAccess.value || this.accessLevels.ftpLimited.value,
                    sshAccess: this.accessLevels.sshAccess.value,
                    statsAccess: this.accessLevels.statsAccess.value,
                    homeDir: this.homeDir
                };
                this.webspace.accesses.push(access);
                const accesses = this.webspace.accesses;
                void this.webhostingRobot.updateWebspace(this.webspace, accesses)
                    .then((response) => {
                        if (['success', 'pending'].includes(response.status)) {
                            void this.navigation.go(this.backLink, null, {reload: true});
                        }
                    });
            });
    };

    public cancel = (): void => {
        void this.navigation.go(this.backLink);
    };
}

export class TemplateWebspaceAccessAddUserOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        webspace: '<'
    };
    public controller = TemplateWebspaceAccessAddUserOverviewController;
    public controllerAs = 'TemplateWebspaceAccessAddUserOverviewCtrl';
    public template = require('./access-add-user-overview-template.html');
}
