import { DnsComposerService, DnsZoneModelService } from '@/services';
import * as ng from 'angular';
import * as Types from '@/types';
import { DnsApi } from '@/types';
import { ViewTypes } from '@/types/view-types';

export class OrganismDnsWizardWrapperController {
    public static $inject: string[] = ['$state', '$timeout', 'dnsComposer', 'dnsZoneModel'];

    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string;
    public outerProductFamily: string;
    public productFamily: string;
    public account: Types.AccountApi.Account;
    public productCode: string;
    public errorList: any[] = [];
    public zone: DnsApi.ZoneConfig;
    public zoneHasBeenPreselected = false;

    public dnsComposerObject: Types.ViewTypes.WizardComposerObject = {
        apiObject: {},
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private dnsComposer: DnsComposerService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    public $onInit() {
        this.zone = this.$state.$current.locals.globals.zone;
        this.zoneHasBeenPreselected = typeof this.zone?.nameUnicode === 'string';
    }

    public loadSummaryView = async (metadata: ViewTypes.ProductConfigDnsObject) => {
        let builder;
        metadata.isRecreate = this.zoneHasBeenPreselected;
        this.dnsComposerObject.summaryObject = [];
        metadata.productFamily = this.productFamily;

        if (metadata.isRecreate) {
            metadata.productFamily = 'dns-zone';
            this.productFamily = 'dns-zone';
            metadata.account.id = this.zone.accountId;
        }

        if (this.productFamily === 'dns-zone') {
            builder = this.dnsComposer.buildDnsApiObject;
        } else { // DDNS Host
            builder = this.dnsComposer.buildDdnsApiObject;
        }

        builder(metadata as (ViewTypes.ProductConfigDnsObject & ViewTypes.ProductConfigDdnsObject)).then(
            (composerObject) => {
                if (composerObject.productFamily === 'dns-zone') {
                    this.errorList = [];
                    this._checkZoneValidity(composerObject).then(
                        (apiCheckResults) => {
                            if (apiCheckResults.response?.errors && apiCheckResults.response?.errors?.length > 0) {
                                this.$timeout(() => {
                                    this.errorList = apiCheckResults.response.errors;
                                });
                            } else {
                                this.$timeout(() => {
                                    this.dnsComposerObject = composerObject;
                                    this.viewType = 'confirm';
                                });
                            }
                        }
                    );
                } else {
                    this.$timeout(() => {
                        this.dnsComposerObject = composerObject;
                        this.viewType = this.productFamily === 'dns-zone'
                            ? 'confirm'
                            : 'summary';
                    });
                }
            }
        );
    };

    public loadConfirmView = () => { // used in template
        this.viewType = 'confirm';
    };

    private _checkZoneValidity = (composerObject: any): Promise<any> => {
        const zoneConfig = composerObject.apiObject.zoneConfig[0];
        const masterIp = (zoneConfig.options === undefined) ? zoneConfig.masterIp : zoneConfig.options.masterIp;
        if (zoneConfig.type === 'SLAVE') {
            return this.dnsZoneModel.checkZoneCreate(
                zoneConfig.name,
                [],
                {
                    masterIp: masterIp
                },
                null,
                zoneConfig.type,
                null,
                zoneConfig.accountId,
                this.zoneHasBeenPreselected
            );
        } else {
            return this.dnsZoneModel.checkZoneCreate(
                zoneConfig.name,
                zoneConfig.records,
                zoneConfig.options,
                zoneConfig.nameserverSetId,
                zoneConfig.type,
                zoneConfig.dnsSecOptions,
                zoneConfig.owner,
                this.zoneHasBeenPreselected
            );
        }
    };
}

export class OrganismDnsWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        outerAccount: '<?account',
        outerProductCode: '<?productCode',
        outerProductFamily: '<?productFamily'
    };
    public template = require('./dns-wizard-wrapper.html');
    public controller = OrganismDnsWizardWrapperController;
}
