/* eslint-disable */

import ng from 'angular';

import { BillingHelperService } from '@/services/';
import { UIRegExp } from '@/configuration';
import {
    CalculatePrice, CalculatePriceFilter, CalculatePriceTaxFilter, CalculatePriceTaxType,
    ConvertAmountFilter
} from '@/filters';
import { AccountApi, BillingApi } from '@/types';
import * as Types from '@/types';

import { AuthContextService } from '../auth-context';
import { AccountHelperService } from '../helpers';

export class PriceHelperService {
    public static $inject: string[] = [
        '$translate',
        'accountHelper',
        'calculatePriceFilter',
        'calculatePriceTaxFilter',
        'convertAmountFilter',
        'billingHelper',
    ];

    private static includedStorage = {
        databaseStorage: 512
    };
    private static units = {
        databaseStorage: 512
    };

    public constructor(
        private $translate: ng.translate.ITranslateService,
        public accountHelper: AccountHelperService,
        private calculatePriceFilter: CalculatePriceFilter,
        private calculatePriceTaxFilter: CalculatePriceTaxFilter,
        private convertAmountFilter: ConvertAmountFilter,
        private billingHelper: BillingHelperService,
    ) {}

    /**
     *  Reads the contractPeriod ('monthly', 'yearly') and formats it to:
     * 'pro Monat, monatliche Abrechnung' or 'pro Jahr, jährliche Abrechnung'
     */
    public getBillingPeriod(contractPeriod: string): string {
        switch (contractPeriod + '') {
            case 'monthly':
            case '1':
                return (
                    this.$translate.instant('TR_090119-04522d_TR')
                    + ', '
                    + this.$translate.instant('TR_260819-54c6c0_TR')
                );
            case 'quarterly':
            case '3':
                return (
                    this.$translate.instant('TR_260819-a40691_TR')
                    + ', '
                    + this.$translate.instant('TR_260819-2c5466_TR')
                );
            case 'semiannually':
            case '6':
                return (
                    this.$translate.instant('TR_260819-ebcc34_TR')
                    + ', '
                    + this.$translate.instant('TR_260819-e1ca5f_TR')
                );
            case 'annually':
            case '12':
                return (
                    this.$translate.instant('TR_090119-c59c72_TR')
                    + ', '
                    + this.$translate.instant('TR_260819-b12ff6_TR')
                );
        }

        return '';
    }

    // TODO refactor to be more stable
    // this works for now but might fail when format of product codes is changed
    public getContractPeriodFromProductCode = (productCode: string) => {
        return productCode.split('-').slice(-1)[0].replace('m', '');
    }

    public setTotalPriceIndex = (): string => {
        return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
    };

    public getProductCurrency = (priceObject: BillingApi.ArticlePurchasePrice, showEur?: boolean): string => {
        let currency = '';
        showEur = showEur || false;

        if ([undefined, null].indexOf(priceObject) < 0 && [undefined, null].indexOf(priceObject.currency) < 0) {
            currency = showEur ? 'eur' : priceObject.currency.toLowerCase();
        }

        return currency.toUpperCase();
    };

    public bruttoNettoText = (priceObject: BillingApi.ArticlePurchasePrice, isCommercialCustomer?: boolean): string => {
        if (isCommercialCustomer === undefined) {
            isCommercialCustomer = AuthContextService.account.isCommercialCustomer;
        }

        if ([undefined, null].indexOf(priceObject) >= 0 || [undefined, null].indexOf(priceObject.currency) >= 0) {
            return '';
        }

        const brutto = this.$translate.instant('BILLING.LABEL.GROSS');
        const netto = this.$translate.instant('BILLING.LABEL.NET');

        if (!isCommercialCustomer) {
            return brutto;
        }

        const currency = priceObject.currency.toUpperCase();
        const price = this.calculatePrice(priceObject, isCommercialCustomer)
            * (1 + priceObject.amounts[0].vatRate / 10000);

        return `${netto} (${this.convertAmountFilter(price, currency)} ${currency} ${brutto})`;
    };

    public calculatePrice = (
        priceObject: BillingApi.ArticlePurchasePrice,
        isCommercialCustomer?: boolean,
        ignoreCommercial?: boolean,
        showPromotionPrice?: boolean,
        exchangeRatio?: BillingApi.ExchangeRatio | number,
        showBasisCurrency?: boolean
    ): null | number => {
        showPromotionPrice = showPromotionPrice || false;
        ignoreCommercial = ignoreCommercial || false;
        if ([undefined, null].indexOf(showBasisCurrency) >= 0) {
            showBasisCurrency = true;
        }

        if ([undefined, null].indexOf(isCommercialCustomer) >= 0 && !ignoreCommercial) {
            isCommercialCustomer = AuthContextService.account.isCommercialCustomer;
        }

        switch (true) {
            case [undefined, null].indexOf(priceObject) >= 0:
            case priceObject.netAmount === 0:
                // if price is not set
                return 0;
            case priceObject.netAmount === -1:
                // rootAdmin or user without right BIL_LIST_ARTICLE_PRICES
                return null;
            case showPromotionPrice:
                if (!showBasisCurrency) {
                    return isCommercialCustomer
                    ? CalculatePrice.roundDigits(priceObject.promotionalNetAmountInBaseCurrency / 10000, 2)
                    // priceObject.promotionalNetAmount is given, so calculate price
                    // Are we sure, what we are doing here ... i am not sure
                    : this.calculatePriceFilter({
                        grossPrice: priceObject.promotionalGrossAmountInBaseCurrency
                    });
                } else {
                    return isCommercialCustomer
                    ? CalculatePrice.roundDigits(priceObject.promotionalNetAmount / 10000, 2)
                    // priceObject.promotionalNetAmount is given, so calculate price
                    // Are we sure, what we are doing here ... i am not sure
                    : this.calculatePriceFilter({
                        grossPrice: priceObject.promotionalGrossAmount
                    });
                }
            case isCommercialCustomer && !showBasisCurrency:
                return CalculatePrice.roundDigits(priceObject.netAmount / 10000, 2);
            case isCommercialCustomer:
                return CalculatePrice.roundDigits(priceObject.netAmountInBaseCurrency / 10000, 2);
            case Number.isNaN(priceObject.grossAmountInBaseCurrency):
            case !showBasisCurrency:
            case [null, undefined].indexOf(exchangeRatio) < 0:
                return this.calculatePriceFilter({
                    grossPrice: priceObject.grossAmount
                });
            case !isCommercialCustomer:
            case ignoreCommercial:
                return CalculatePrice.roundDigits(priceObject.grossAmountInBaseCurrency / 10000, 2);
            default:
                return CalculatePrice.roundDigits(priceObject.netAmountInBaseCurrency / 10000, 2);
        }
    };

    /**
     * The return value must be stored where the function is used with ng.copy().
     * Otherwise this return value is stored as reference, which leads to strange results (?!)
     */
    public calculatePriceTax = (
        priceObject: BillingApi.ArticlePurchasePrice,
        account: AccountApi.Account | AccountApi.Subaccount,
        showPromotionPrice?: boolean
    ): CalculatePriceTaxType => {
        return this.calculatePriceTaxFilter(priceObject, account, 1, showPromotionPrice);
    };

    public replaceBillingCycleInProductCodeTemplate = (productCodeTemplate: string, contractPeriod: string): string => {
        if ([undefined, null].includes(productCodeTemplate)) {
            return '';
        }
        return productCodeTemplate.replace(UIRegExp.BillingCyclePlaceholder, contractPeriod);
    };

    public calculateAndShowPrice(
        price: BillingApi.ArticlePurchasePrice,
        account: AccountApi.Account | AccountApi.Subaccount,
        ignoreCommercial = false,
        showPromotionPrice = false,
        hideVatHint = false,
        showBasisCurrency = false,
        amount = 1
    ): string {
        if (this.accountHelper.isRootAdmin(account)) {
            return this.$translate.instant('d32cbe5d-f0c9-4c61-bfbd-b96446e0a774');
        }

        // Account has no right to see prices
        if (price.netAmount === -1) {
            return this.$translate.instant('TR_301019-2f4ae4_TR');
        }

        amount = isNaN(parseInt(`${amount}`, 10)) ? 1 : parseInt(`${amount}`, 10);

        const currency =
            showBasisCurrency && price.exchangeRatio
                ? price.exchangeRatio.baseCurrency.toLocaleUpperCase()
                : price.currency.toLocaleUpperCase();
        const exchangeRatio = price.exchangeRatio?.exchangeRatio;

        const calculatedPrice = this.calculatePrice(
            price,
            account.isCommercialCustomer,
            ignoreCommercial,
            showPromotionPrice,
            exchangeRatio,
            showBasisCurrency
        );

        return this.convertPriceToText(amount, calculatedPrice, currency, hideVatHint, account);
    }

    public convertPriceToText(
        amount: number,
        calculatedPrice: number,
        currency: string,
        hideVatHint: boolean,
        account: AccountApi.Account | AccountApi.Subaccount
    ): string {
        if (calculatedPrice === 0) {
            return this.$translate.instant('ff319345-537f-4dd9-8ae4-134435f7c4d8');
        } else {
            const priceValue = this.convertAmountFilter(amount * calculatedPrice, currency);
            if (hideVatHint) {
                return priceValue;
            }

            return !this.accountHelper.isCommercialCustomer(account)
                ? priceValue
                : priceValue + ' ' + this.$translate.instant('TR_130219-775cda_TR');
        }
    }

    /*
     * get productPriceObject from given productCode
     */
    public async getPriceObjectFromProductCode(
        productCode: string,
        account: AccountApi.Account | AccountApi.Subaccount = AuthContextService?.account,
    ) {
        return this.billingHelper.getPriceByProductCode(productCode, account?.id).then((response) => {
            if (!response) return undefined;
            return response as BillingApi.ArticlePurchasePrice;
        });
    }

    /*
     * get productPriceObject from getPriceObjectFromProductCode,
     * then calculate price and return price string
     */
    public async getShowPriceFromProductCode(
        productCode: string,
        account: AccountApi.Account | AccountApi.Subaccount = AuthContextService?.account
    ) {
        const priceObject = await this.getPriceObjectFromProductCode(
            productCode,
            account,
        );
        return this.calculateAndShowPrice(
            priceObject,
            account,
        );
    }

    /*
     * get productPriceObject from given productCode,
     * then replace price parameters by voucher code parameters
     * this is not optimal, should be fixed by PUI-7212
     */
    public async getPriceFromVoucherAndProductCode(
        voucher: Types.ViewTypes.ProductConfigVoucher,
        productCode: string,
        account: AccountApi.Account | AccountApi.Subaccount = AuthContextService?.account,
    ) {
        const priceObject: BillingApi.ArticlePurchasePrice = await this.getPriceObjectFromProductCode(
            productCode,
            account,
        );
        return this.getPriceFromVoucherAndPriceObject(voucher, priceObject);
    }

    /*
     * get productPriceObject from getPriceFromVoucherAndProductCode,
     * then calculate price and return price string
     */
    public async getShowPriceFromVoucherAndProductCode(
        voucher: Types.ViewTypes.ProductConfigVoucher,
        productCode: string,
        account: AccountApi.Account | AccountApi.Subaccount = AuthContextService?.account,
    ) {
        const priceObject = await this.getPriceFromVoucherAndProductCode(
            voucher,
            productCode,
            account,
        );
        return this.calculateAndShowPrice(
            priceObject,
            account,
        );
    }

    public getPriceFromVoucherAndPriceObject(
        voucher: Types.ViewTypes.ProductConfigVoucher,
        priceObject: BillingApi.ArticlePurchasePrice,
    ) {
        if (!voucher.eligibleProductCodes.includes(priceObject.productCode)) return priceObject;
        let res = JSON.parse(JSON.stringify(priceObject));

        // vouchers are always in euro, waiting for backend to fix this
        // manually replacing attributes in priceObject
        res.grossAmount = voucher.grossAmount;
        res.netAmount = voucher.netAmount;
        res.exchangeRatio = null;
        res.netAmountInBaseCurrency = voucher.netAmount;
        res.grossAmountInBaseCurrency = voucher.grossAmount;
        res.amounts = [{
            grossAmount: voucher.grossAmount,
            netAmount: voucher.netAmount,
            vatRate: voucher.vatRate,
        }];
        return res;
    }

    public getVoucherEndDate(voucher: Types.ViewTypes.ProductConfigVoucher): string {
        const endDate = this.addDays(new Date(), voucher.periodInDays);

        let day = endDate.getDate();
        let dayString = day < 10 ? '0' + day.toString() : day.toString();
        let month = endDate.getMonth() + 1;
        let monthString = month < 10 ? '0' + month.toString() : month.toString();
        let yearString = endDate.getFullYear().toString();

        let formattedEndDate = `${dayString}.${monthString}.${yearString}`;
        return formattedEndDate;
    }

    private addDays(date: any, days: any): Date {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

}
