import * as Sentry from '@sentry/browser';
import { RequestCounterService } from '@/scripts/development/request-counter';
import { AlertManagerService } from '../alert-manager';
import { AuthContextService } from '../auth-context';
import { Cache, CacheService } from '../cache';
import { ClientTransactionIdContext } from '../client-transaction-id-context';
import { ErrorMapperService } from '../error-mapper';
import { ApiError, ApiErrors, HttpError, IgnoredApiCodes, InterfaceError, SentryErrorEmitterService } from '../errors';
import { IgnoredHttpCodes } from '../errors/ignored-http-errors.enum';
import { NavigationService } from '../navigation';
import { Deferred } from '../deferred';

import ng from 'angular';
import * as Types from '@/types';

import { RequestDebugFlag, UnavailableServiceBehavior } from './enums';

export class Request {
    private static cancelAllDeferred: Deferred<string>;

    public static cancelAllRequests = (action?: string): Promise<string> => {
        if ([undefined, null].indexOf(Request.cancelAllDeferred) < 0) {
            const promise = Request.cancelAllDeferred.promise;

            Request.cancelAllDeferred.resolve(action ? action : 'unspecified action');
            Request.cancelAllDeferred = null;

            return promise;
        }

        return Promise.resolve(action);
    };

    public cache: Cache<any>;
    private _timeout: number | Promise<any>;
    private _ignoreUnavailable: UnavailableServiceBehavior = UnavailableServiceBehavior.CATCH;
    private _authenticated = true;
    private _ownerAccountId: string;
    private _skipPagination: boolean;
    private _debugFlags: RequestDebugFlag[] = [];

    private cacheConfiguration = {
        bustAll: false,
        bustMethods: [] as string[],
        bustTags: [] as string[],
        useCache: false
    };

    private errorHandlingConfiguration = {
        ignoredCodes: [] as number[]
    };

    constructor(
        private _cache: CacheService,
        private $http: ng.IHttpService,
        private alertManager: AlertManagerService,
        private errorMapper: ErrorMapperService,
        private navigation: NavigationService,
        private requestCounter: RequestCounterService,
        private baseUrl: string,
        private robot: string,
        private method: string
    ) {
        this.cache = this._cache.get(`${this.robot}::${this.method}`);
    }

    /**
     * Specify whether or not a request should be cached.
     * Requests are not cached by default.
     *
     * @param value Whether or not to cache the request. Defaults to true.
     */
    public useCache: (value?: boolean) => Request =
    (value?) => {
        this.cacheConfiguration.useCache = [undefined, null, true].indexOf(value) >= 0;

        return this;
    };

    /**
     * Add a tag to the cache for this request. Tags can be used to clear associated caches.
     *
     * @param tag The tag to be added.
     */
    public tagCache: (tag: string) => Request =
    (tag) => {
        this.cache.flag(tag);

        return this;
    };

    /**
     * Set a timeout for the cache for this request.
     *
     * @param timeout The timeout in milliseconds. Defaults to undefined (no timeout).
     */
    public cacheTimeout: (timeout?: number) => Request =
    (timeout?) => {
        if ([null, 0].indexOf(timeout) < 0) {
            this.cache.timeout(timeout);
        }

        return this;
    };

    /**
     * Bust a specific cache when this request is executed successfully.
     *
     * @param service The service the cache to be busted belongs to.
     * @param method The method whose cache is to be busted.
     */
    public bustCache: (service: string, method: string) => Request =
    (service, method) => {
        this.cacheConfiguration.bustMethods.push(`${service}::${method}`);

        return this;
    };

    /**
     * Bust all caches with a specific tag when this request is executed successfully.
     *
     * @param tag The tag to be used for busting associated caches.
     */
    public bustTaggedCaches: (tag: string) => Request =
    (tag) => {
        this.cacheConfiguration.bustTags.push(tag);

        return this;
    };

    /**
     * Bust all caches when this request is executed successfully.
     */
    public bustAllCaches: () => Request =
    () => {
        this.cacheConfiguration.bustAll = true;

        return this;
    };

    /**
     * Set a timeout value or cancellation promise for this request.
     *
     * @param value
     *      Timeout in milliseconds or promise that cancels the request when fulfilled.
     *      Defaults to 0 (no timeout).
     */
    public timeout: (value: number | Promise<any>) => Request =
    (value: number) => {
        if ([undefined, null, 0].indexOf(value) >= 0) {
            this._timeout = undefined;
        } else {
            this._timeout = value;
        }

        return this;
    };

    /**
     * Set whether or not to ignore errors due to services being unavailable.
     *
     * @param value Behaviour when service is unavailable. Defaults to CATCH.
     */
    public ignoreUnavailable: (value?: UnavailableServiceBehavior) => Request =
    (value?) => {
        if ([undefined, null, UnavailableServiceBehavior.IGNORE].indexOf(value) >= 0) {
            this._ignoreUnavailable = UnavailableServiceBehavior.IGNORE;
        } else {
            this._ignoreUnavailable = UnavailableServiceBehavior.CATCH;
        }

        return this;
    };

    public ignoreError: (errorCode: number) => Request =
    (errorCode) => {
        if (this.errorHandlingConfiguration.ignoredCodes.indexOf(errorCode) < 0) {
            this.errorHandlingConfiguration.ignoredCodes.push(errorCode);
        }

        return this;
    };

    /**
     * Set this request to be executed without authentication.
     * By default, all requests are executed with authentication.
     */
    public unauthenticated: () => Request =
    () => {
        this._authenticated = false;

        return this;
    };

    /**
     * Set an owner account id.
     *
     * @param value oOwner account id for this request
     */
    public ownerAccountId: (value?: string) => Request =
    (value?) => {
        if ([undefined, null, ''].indexOf(value) < 0) {
            this._ownerAccountId = value;
        } else {
            this._ownerAccountId = undefined;
        }

        return this;
    };

    /**
     * Configure whether or not to skip the pagination for list requests. Pagination is not skipped by default.
     *
     * @param value Whether or not to skip pagination. Defaults to true.
     */
    public skipPagination: (value?: boolean) => Request =
    (value) => {
        this._skipPagination = [undefined, null, true].indexOf(value) >= 0;

        return this;
    };

    /**
     * Set debug flags for this request. No flags are set by default.
     *
     * @param flags Array of debug flags to set. Defaults to all known flags.
     */
    public debug: (flags?: RequestDebugFlag[]) => Request =
    (flags?) => {
        if ([undefined, null].indexOf(flags) >= 0) {
            this._debugFlags = [
                RequestDebugFlag.DEBUG,
                RequestDebugFlag.LOG_DATA,
                RequestDebugFlag.STACK_TRACE,
                RequestDebugFlag.NO_REQUEST
            ];
        } else {
            this._debugFlags = flags;
        }

        return this;
    };

    /**
     * Execute the request with supplied data.
     *
     * @param data Request data
     *
     * @returns Promise
     */
    public execute: (data: { [key: string]: any }) => Promise<any> =
    (data) => {
        const debugOutputThreshold = this._debugFlags
        .filter(
            (flag) => [RequestDebugFlag.DEBUG, RequestDebugFlag.NO_REQUEST].indexOf(flag) >= 0
        )
        .length;

        if (this._debugFlags.indexOf(RequestDebugFlag.DEBUG) >= 0) {
            // This debugger is enabled only when the corresponding debug flag (RequestDebugFlag.DEBUG) is set.
            // The stack should then allow you to find out, for example, where exactly a request is made.

            debugger; // eslint-disable-line no-debugger
        }

        if (this._debugFlags.length > debugOutputThreshold) {
            console.groupCollapsed(`DEBUG: REQUEST "${this.robot}::${this.method}"`);
        }

        if (this._authenticated && !AuthContextService.authenticated) {
            return Promise.reject(new InterfaceError('Not logged in', 1));
        }

        data = this.prepareRequestData(data);

        if (this._debugFlags.indexOf(RequestDebugFlag.LOG_DATA) >= 0) {
            console.log('Request data:', JSON.stringify(data, null, 4)); // eslint-disable-line no-console
        }

        const httpConfig: ng.IRequestShortcutConfig = {
            headers: {
                Accept: 'application/json'
            },
            timeout: this._timeout
        };

        const result = this.handleCaching(data, httpConfig);

        if (this._debugFlags.indexOf(RequestDebugFlag.STACK_TRACE) >= 0) {
            console.trace(); // eslint-disable-line no-console
        }

        if (this._debugFlags.length > debugOutputThreshold) {
            console.groupEnd();
        }

        return result;
    };

    /**
     * Add additional request parameters from Request configuration.
     *
     * @param data Request data
     *
     * @returns Enhanced request data
     */
    private prepareRequestData = (data: { [key: string]: any }) => {
        data = data || {};

        if (this._authenticated) {
            data.authToken = AuthContextService.token;
        }

        if ([undefined, null, ''].indexOf(this._ownerAccountId) < 0) {
            data.ownerAccountId = this._ownerAccountId;
        }

        data.clientTransactionId = ClientTransactionIdContext.getNextId();

        data.skipPagination = this._skipPagination;

        return data;
    };

    /**
     * Execute the request and do standard handling.
     */
    private executeRequest = (data: any, httpConfig: any): Promise<any> => {
        const requestHash = this.getRequestHash(data);

        if ([undefined, null].indexOf(Request.cancelAllDeferred) >= 0) {
            Request.cancelAllDeferred = new Deferred();
        }

        let cancelled: false | string = false;
        void Request.cancelAllDeferred.promise
            .then((action: string) => cancelled = action);

        this.requestCounter.increase();
        let promise: Promise<any>;

        if (this._debugFlags.indexOf(RequestDebugFlag.NO_REQUEST) < 0) {
            promise = this.$http.post(this.baseUrl + this.method, data, httpConfig)
            .catch(this.responseFailure) as Promise<any>;
        } else {
            return Promise.reject(new Error('Request prevented for debugging purposes.'));
        }

        if (this._ignoreUnavailable === UnavailableServiceBehavior.CATCH) {
            promise = promise.then(undefined, this.checkUnavailable);
        }

        return promise.then(
            (response) => {
                if ([undefined, null].indexOf(response) < 0) {
                    Sentry.addBreadcrumb({
                        type: "debug",
                        category: "xhr",
                        message: "Server transaction Id: " + response.data?.metadata?.serverTransactionId + " with status: " + response.data?.status,
                        level: Sentry.Severity.Info,
                    });
                }
                if (cancelled === false) {
                    return this.responseSuccess(response);
                }

                cancelled = `Request ${requestHash} cancelled by ${cancelled}.`;

                return Promise.reject(cancelled);
            }
        )
        .then(undefined, this.checkExpired);
    };

    /**
     * Get a hash, mostly used for caching.
     */
    private getRequestHash(data: any) {
        const dataWithoutId = JSON.parse(JSON.stringify(data));
        delete(dataWithoutId.clientTransactionId);
        const dataHash = JSON.stringify(dataWithoutId);
        return `{${this.robot}::${this.method}|${dataHash}}`;
    }

    /**
     * Handle caching and execute the request.
     */
    private handleCaching = (data: { [key: string]: any }, httpConfig: any): Promise<any> => {
        let promise: Promise<any>;

        if (this.cacheConfiguration.useCache === false) {
            promise = this.executeRequest(data, httpConfig);
        } else {
            const requestHash = this.getRequestHash(data);

            if (this.cache.has(requestHash)) {
                promise = this.cache.get(requestHash);
            } else {
                promise = this.executeRequest(data, httpConfig);

                this.cache.add(requestHash, promise);
            }
        }

        return promise.then(
            (result) => {
                if (this.cacheConfiguration.bustAll) {
                    this._cache.clearAll();
                } else {
                    this.cacheConfiguration.bustTags.map((tag) => this._cache.clearFlagged(tag));
                    this.cacheConfiguration.bustMethods.map((method) => this._cache.get(method).clear());
                }

                return result;
            }
        );
    };

    /**
     * Handles HTTP errors
     */
    private responseFailure = (response: ng.IHttpResponse<any>) => {
        // Einige Fehler müssen nicht im Sentry geloggt werden
        if (!(response.status in IgnoredHttpCodes)) {
            const errMsg = 'API responded with HTTP error code ' + response.status;

            SentryErrorEmitterService.sendSentryReport(
                errMsg,
                {
                    apiUrl: response.config.url,
                    method: response.config.method,
                    requestData: response.config.data,
                    url: response.config.url,
                    errorCode: `${response.status}`,
                    response: response as any,
                },
                {
                    apiUrl: response.config.url,
                    errorCode: `${response.status}`,
                    key: 'api',
                    clientTransactionId: response.config.data.clientTransactionId,
                }
            )

            this.alertManager.error(errMsg, 'API Error');
        }

        if (response.status === IgnoredHttpCodes.UNAVAILABLE) {
            return {
                config: response.config,
                data: {
                    errors: [
                        {
                            code: 10002,
                            context: '',
                            details: [],
                            text: 'Error connecting to backend. Please try again, if the problem persists contact support.'
                        }
                    ],
                    metadata: {},
                    status: 'error',
                    warnings: []
                }
            };
        }

        if (response.status === IgnoredHttpCodes.MAINTENANCE) {
            return {
                config: response.config,
                data: {
                    errors: [
                        {
                            code: 10001,
                            context: '',
                            details: [],
                            text: 'Maintenance in progress. Please try again later.'
                        }
                    ],
                    metadata: {},
                    status: 'error',
                    warnings: []
                }
            };
        }

        if (response.data && response.data.status === 'error') {
            const errors = response.data.errors.filter((error: any) => this.removeIgnoredErrors(error));

            if (errors.length > 0) {
                throw new ApiError(errors[0]);
            }
        }

        if (response.xhrStatus === 'abort') {
            return Promise.reject(`Request aborted: ${response.config.url}`);
        }

        SentryErrorEmitterService.sendSentryReport(
            "Response Failure. Under observation, what's in the response?",
            {
                response: {
                    config: {
                        cache: JSON.stringify(response.config.cache),
                        data: JSON.stringify(response.config.data),
                        jsonpCallbackParam: response.config.jsonpCallbackParam,
                        method: JSON.stringify(response.config.method),
                        params: JSON.stringify(response.config.params),
                        responseType: JSON.stringify(response.config.responseType),
                    },
                    data: response.data,
                    status: {
                        statusNumber: response.status,
                        statusText: response.statusText,
                        xhrStatus: response.xhrStatus,
                    },
                },
            },
            {
                errorStatus: "observed",
                linkedError: "HttpError: No error message",
            },
            Sentry.Severity.Warning,
        );

        return Promise.reject(new HttpError(response));
    };

    /**
     * Handle errors due to robots being in maintenance or otherwise unavailable.
     */
    private checkUnavailable = (reason: any) => {
       // Check if the error means the backend is unavailable
       switch (reason.code) {
            case ApiErrors.GENERAL.ROBOT_IN_MAINTENANCE:
                this.alertManager.robotInMaintenance(this.robot);
                break;
            case ApiErrors.GENERAL.ROBOT_UNAVAILABLE:
                this.alertManager.robotNotAvailable(this.robot);
                break;
            default: break;
       }

       return Promise.reject(reason);
    };

    /**
     * Handle API errors
     */
    private responseSuccess = (response: ng.IHttpResponse<any>): Types.UI.APIResponse<any> => {
        const apiResponse: Types.UI.APIResponse<any> = response.data;

        const errors = apiResponse.errors.filter((error) => this.removeIgnoredErrors(error));

        if (apiResponse.status !== 'error' || errors.length === 0) {
            return apiResponse;
        }

        let errMsg = '';

        if (this.errorMapper.has(errors[0].code)) {
            errors[0].text = this.errorMapper.map(errors[0]); // Set translated error text
            this.alertManager.error(
                errors[0].text,
                'Error',
                errors[0].code,
                apiResponse.metadata.serverTransactionId
            );
        } else if (!(errors[0].code in IgnoredApiCodes)) {
            errMsg = `API responded with error ${errors[0].code}`
            + (
                ([undefined, null, ''].indexOf(errors[0].text) < 0)
                ? `: ${errors[0].text}`
                : ''
            );

            SentryErrorEmitterService.sendSentryReport(
                errMsg,
                {
                    apiUrl: response.config.url,
                    method: response.config.method,
                    requestData: response.config.data,
                    url: response.config.url,
                    errorCode: `${errors[0].code}`,
                    metadata: apiResponse.metadata as any,
                },
                {
                    apiUrl: response.config.url,
                    errorCode: `${errors[0].code}`,
                    key: 'api',
                    clientTransactionId: response.config.data.clientTransactionId,
                }
            );

            this.alertManager.error(
                errMsg,
                'API Error',
                errors[0].code,
                apiResponse.metadata.serverTransactionId
            );
        }

        throw new ApiError(errors[0], apiResponse.metadata.serverTransactionId);
    };

    /**
     * Go to login if backend says the session is expired
     */
    private checkExpired = (reason: any) => {
        // Check if the session has expired
        if (
            [
                ApiErrors.GENERAL.SESSION_INVALID,
                ApiErrors.GENERAL.SESSION_EXPIRED,
                ApiErrors.GENERAL.API_KEY_INVALID
            ].indexOf(reason.code) >= 0
        ) {
            this.navigation.onSessionExpired();
        }

        return Promise.reject(reason);
    };

    /**
     * Filter for ignoring errors
     */
    private removeIgnoredErrors: (error: Types.keenRobotsCommon.ErrorOrWarning) => boolean =
    (error) => {
        if (this.errorHandlingConfiguration.ignoredCodes.indexOf(error.code) >= 0) {
            return false;
        }

        return true;
    };
}
