import ng from 'angular';

import { CycleBillingTranslationConst } from '@/configuration';
import { AuthContextService, PriceCacheService } from '@/services';
import { BillingApi } from '@/types';

import { CalculatePrice, CalculatePriceFilter } from './calculate-price';
import { TranslateProductCodeFilter } from './translate-product-code';

interface Article {
    productCode: string;
    quantity: number;
    owner: string;
}

export class ArticleDetails {
    public static $inject: string[] = [
        '$translate',
        'calculatePriceFilter',
        'priceCache',
        'translateProductCodeFilter'
    ];

    private defaultType: string;

    public static Factory = (
        $translate: ng.translate.ITranslateService,
        calculatePriceFilter: CalculatePriceFilter,
        priceCache: PriceCacheService,
        translateProductCodeFilter: TranslateProductCodeFilter
    ): (v: Article) => Promise<Article> => {
        const filter = new ArticleDetails(
            $translate, calculatePriceFilter, priceCache, translateProductCodeFilter
        );

        return (value: Article) => {
            if (filter.isArticle(value)) {
                return filter.extend(value);
            } else {
                return Promise.resolve(value);
            }
        };
    };

    private static empty = (value: unknown): boolean => {
        return [undefined, null, '', [], {}].indexOf(value) >= 0;
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private calculatePriceFilter: CalculatePriceFilter,
        private priceCache: PriceCacheService,
        private translateProductCodeFilter: TranslateProductCodeFilter
    ) {
        this.defaultType = AuthContextService.account.isCommercialCustomer ? 'net' : 'gross';
    }

    private getPrices = (article: Article, price: BillingApi.ArticlePurchasePrice) => {
        const exchangeRatio = [undefined, null].indexOf(price.exchangeRatio) >= 0
            ? 10000
            : price.exchangeRatio.exchangeRatio;
        const basePrice = [undefined, null].indexOf(price.promotionalNetAmount) >= 0
            ? price.netAmount : price.promotionalNetAmount;
        const eurBasePrice = CalculatePrice.roundDigits((basePrice * 10000) / exchangeRatio, 0);
        const eurOriginalPrice = CalculatePrice.roundDigits((price.netAmount * 10000) / exchangeRatio, 0);

        const out = {
            single: {
                default: this.calculatePriceFilter(
                    basePrice,
                    price.amounts[0].vatRate,
                    1,
                    this.defaultType,
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                defaultEur: this.calculatePriceFilter(
                    eurBasePrice,
                    price.amounts[0].vatRate,
                    1,
                    this.defaultType,
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                gross: this.calculatePriceFilter(
                    basePrice,
                    price.amounts[0].vatRate,
                    1,
                    'gross',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                grossEur: this.calculatePriceFilter(
                    eurBasePrice,
                    price.amounts[0].vatRate,
                    1,
                    'gross',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                net: this.calculatePriceFilter(
                    basePrice,
                    price.amounts[0].vatRate,
                    1,
                    'net',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                netEur: this.calculatePriceFilter(
                    eurBasePrice,
                    price.amounts[0].vatRate,
                    1,
                    'net',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                )
            },
            singleOriginal: {
                default: this.calculatePriceFilter(
                    price.netAmount,
                    price.amounts[0].vatRate,
                    1,
                    this.defaultType,
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                defaultEur: this.calculatePriceFilter(
                    eurOriginalPrice,
                    price.amounts[0].vatRate,
                    1,
                    this.defaultType,
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                gross: this.calculatePriceFilter(
                    price.netAmount,
                    price.amounts[0].vatRate,
                    1,
                    'gross',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                grossEur: this.calculatePriceFilter(
                    eurOriginalPrice,
                    price.amounts[0].vatRate,
                    1,
                    'gross',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                net: this.calculatePriceFilter(
                    price.netAmount,
                    price.amounts[0].vatRate,
                    1,
                    'net',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                netEur: this.calculatePriceFilter(
                    eurOriginalPrice,
                    price.amounts[0].vatRate,
                    1,
                    'net',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                )
            },
            total: {
                default: this.calculatePriceFilter(
                    basePrice,
                    price.amounts[0].vatRate,
                    article.quantity,
                    this.defaultType,
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                defaultEur: this.calculatePriceFilter(
                    eurBasePrice,
                    price.amounts[0].vatRate,
                    article.quantity,
                    this.defaultType,
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                gross: this.calculatePriceFilter(
                    basePrice,
                    price.amounts[0].vatRate,
                    article.quantity,
                    'gross',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                grossEur: this.calculatePriceFilter(
                    eurBasePrice,
                    price.amounts[0].vatRate,
                    article.quantity,
                    'gross',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                net: this.calculatePriceFilter(
                    basePrice,
                    price.amounts[0].vatRate,
                    article.quantity,
                    'net',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                netEur: this.calculatePriceFilter(
                    eurBasePrice,
                    price.amounts[0].vatRate,
                    article.quantity,
                    'net',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                )
            },
            totalOriginal: {
                default: this.calculatePriceFilter(
                    price.netAmount,
                    price.amounts[0].vatRate,
                    article.quantity,
                    this.defaultType,
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                defaultEur: this.calculatePriceFilter(
                    eurOriginalPrice,
                    price.amounts[0].vatRate,
                    article.quantity,
                    this.defaultType,
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                gross: this.calculatePriceFilter(
                    price.netAmount,
                    price.amounts[0].vatRate,
                    article.quantity,
                    'gross',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                grossEur: this.calculatePriceFilter(
                    eurOriginalPrice,
                    price.amounts[0].vatRate,
                    article.quantity,
                    'gross',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                net: this.calculatePriceFilter(
                    price.netAmount,
                    price.amounts[0].vatRate,
                    article.quantity,
                    'net',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                ),
                netEur: this.calculatePriceFilter(
                    eurOriginalPrice,
                    price.amounts[0].vatRate,
                    article.quantity,
                    'net',
                    undefined,
                    false,
                    price.productCode,
                    price.contractPeriod
                )
            }
        };

        return out;
    };

    private isArticle = (value: any): boolean => {
        return !ArticleDetails.empty(value)
        && ['owner', 'productCode', 'quantity'].every((attribute) => !ArticleDetails.empty(value[attribute]))
        && ['number', 'string'].indexOf(typeof value.owner) >= 0
        && typeof value.productCode === 'string'
        && typeof value.quantity === 'number';
    };

    private extend = (article: Article): Promise<Article> => {
        if ([undefined, null, ''].indexOf(article.owner) >= 0) {
            article.owner = AuthContextService.account.id;
        }

        const returnArticle = JSON.parse(JSON.stringify(article));
        const parts = article.productCode.split('-');
        let promises = [];

        returnArticle.displayName = this.translateProductCodeFilter(article.productCode, article.owner);

        const filterByProductCode = (price: BillingApi.ArticlePurchasePrice): boolean => {
            return (price.productCode === article.productCode);
        };

        const getPrice = (prices: BillingApi.ArticlePurchasePrice[]): BillingApi.ArticlePurchasePrice => {
            const matches = prices.filter(filterByProductCode);
            if ([undefined, null].indexOf(matches) < 0 && ng.isArray(matches) && matches.length === 1) {
                return matches[0];
            } else {
                return null;
            }
        };

        let pricesCurrentAccount = [];
        const callbacks = {
            bundle: this.priceCache.listBillingPrices,
            database: this.priceCache.listDatabasePrices,
            domain: (owner: string) => this.priceCache.listDomainPrices(parts[1], owner),
            email: this.priceCache.listMailPrices,
            machine: this.priceCache.listVmPrices,
            ssl: this.priceCache.listSslPrices,
            webhosting: this.priceCache.listWebhostingPrices
        };
        const callback = callbacks[parts[0]];

        const pricesOwner = callback(article.owner);
        if (article.owner !== AuthContextService.account.id) {
            pricesCurrentAccount = callback(AuthContextService.account.id);
        }

        promises = [
            Promise.resolve(pricesOwner)
            .then(getPrice)
            .then(
                (price: BillingApi.ArticlePurchasePrice) => {
                    if ([undefined, null].indexOf(price) < 0) {
                        returnArticle.currency = {};
                        returnArticle.currency.owner = price.currency.toUpperCase();
                        returnArticle.contractPeriod = this.$translate
                            .instant(CycleBillingTranslationConst[price.contractPeriod]);
                        returnArticle.nameFromPrice = price.name;
                        returnArticle.noticePeriod = price.noticePeriod;
                        if ([undefined, null].indexOf(price.noticePeriod) < 0) {
                            returnArticle.noticePeriod += (price.noticePeriod === 1)
                            ? ' ' + this.$translate.instant('c9d4d439-d6be-4eda-93bb-66eb041b46be')
                            : ' ' + this.$translate.instant('db2881be-7da3-44c4-8b24-e7d353391e82');
                        }
                        returnArticle.rawPrice = price.netAmount;
                        returnArticle.vatRate = (price.amounts[0].vatRate / 100) + '%';
                        returnArticle.price = {};
                        returnArticle.price.defaultType = this.defaultType;
                        returnArticle.price.owner = this.getPrices(article, price);
                    }
                }
            ),

            Promise.resolve(pricesCurrentAccount)
            .then(getPrice)
            .then(
                (price) => {
                    if ([undefined, null].indexOf(price) < 0) {
                        if ([undefined, null].indexOf(returnArticle.currency) >= 0) {
                            returnArticle.currency = {};
                        }
                        if ([undefined, null].indexOf(returnArticle.price) >= 0) {
                            returnArticle.price = {};
                        }
                        returnArticle.currency.currentUser = price.currency.toUpperCase();
                        returnArticle.price.currentUser = this.getPrices(article, price);
                    }
                }
            )
        ];

        return Promise.all(promises).then(() => returnArticle);
    };
}
