import { CalculatePrice } from "./calculate-price";

export class ApiValue {
    public static Factory() {
        return ApiValue.parseApiValue;
    }

    public static parseApiValue(value: number) {
        if ([undefined, null].indexOf(value) >= 0 || typeof value !== 'number') {
            return value;
        } else {
            return CalculatePrice.roundDigits(value / 10000.0, 2);
        }
    }
}
